import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import * as React from "react"
import DesignIcon from "../../images/our-process/Design.svg"
import GrowthIcon from "../../images/our-process/Growth.svg"
import StrategyIcon from "../../images/our-process/Strategy.svg"

import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import AspectRatioOutlinedIcon from "@material-ui/icons/AspectRatioOutlined"
import ImportantDevicesOutlinedIcon from "@material-ui/icons/ImportantDevicesOutlined"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Whatshot from "@material-ui/icons/Whatshot"
import Group from "@material-ui/icons/Group"
import Timeline from "@material-ui/icons/Timeline"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { graphql, PageProps } from "gatsby"

interface MagentoPageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      iconName: string
      color?: string
    }
  }
}
const MagentoPage : React.FC<PageProps<MagentoPageData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, description } = frontmatter
  const feature = [
    {
      title: "Open-source flexibility with extensive customization options",
    },
    {
      title: "Scalable architecture supporting high-volume transactions",
    },
    {
      title: "Built-in B2B functionality",
    },
    {
      title: "Advanced marketing and promotional tools",
    },
    {
      title: "Multi-store and multi-language capabilities",
    },
    {
      title: "Rich ecosystem of extensions and integrations",
    },
  ]
  const experties = [
    {
      title: "Years of experience",
      content:
        " 8+ years of specialized Magento development. 100+ successful Magento projects delivered",
      imgSrc: DesignIcon,
    },
    {
      title: "Notable projects",
      content:
        " Developed a custom Magento solution for a multi-brand fashion retailer, resulting in a 40% increase in online sales. Implemented a complex B2B portal for an industrial equipment manufacturer, streamlining their ordering process and increasing efficiency by 25%",
      imgSrc: GrowthIcon,
    },
    {
      title: "Certifications and partnerships",
      content:
        " Adobe Solution Partner. Multiple team members with Adobe Certified Expert Magento Commerce Developer certifications. Magento Community Insider status",
      imgSrc: StrategyIcon,
    },
  ]
  const items = [
    {
      imgSrc: <ImportantDevicesOutlinedIcon />,
      title: "Development",
      desc: " Custom Magento theme development. Extension development and customization. Progressive Web App (PWA) implementation. Headless commerce solutions",
    },
    {
      imgSrc: <AspectRatioOutlinedIcon />,
      title: "Migration",
      desc: "Seamless migration from other platforms to Magento.  Magento 1 to Magento 2 migrations. Data migration and integrity checks.",
    },
    {
      imgSrc: <GroupOutlinedIcon />,
      title: " Support and maintenance",
      desc: "24/7 Magento support and monitoring. Regular security updates and patch management. Ongoing feature enhancements and bug fixes",
    },
    {
      imgSrc: <GroupWorkOutlinedIcon />,
      title: " Integration",
      desc: " ERP, CRM, and PIM system integrations. Payment gateway and shipping provider setups. Third-party API integrations",
    },
    {
      imgSrc: <TrendingUp />,
      title: "Optimization",
      desc: "Performance optimization and speed enhancements. Search engine optimization (SEO).  Conversion rate optimization (CRO).",
    },
  ]
  const helpItems = [
    {
      icon: <Whatshot />,
      title: "Unique selling points",
      content:
        "Deep understanding of Magento's core architecture and best practices. Proven methodology for successful project delivery. Focus on creating scalable and future-proof solutions",
    },
    {
      icon: <Group />,
      title: "Team expertise",
      content:
        " Dedicated Magento certified developers and solution specialists. In-house UX/UI designers with e-commerce expertise. Experienced project managers and business analysts",
    },
    {
      icon: <Timeline />,
      title: " Process highlights",
      content:
        "Comprehensive discovery and planning phase.  Agile development methodology with regular sprints. Rigorous quality assurance and testing procedures. Smooth deployment and post-launch support",
    },
  ]
  const faqData = [
    {
      question: "How does Magento compare to other e-commerce platforms?",
      answer:
        "Magento stands out for its flexibility, scalability, and robust feature set. It's particularly well-suited for businesses with complex requirements or those planning for significant growth. While it may require more initial setup than some simpler platforms, it offers unparalleled customization options and performance capabilities.",
    },
    {
      question: "  Can Magento handle both B2C and B2B e-commerce?",
      answer:
        "Yes, Magento excels in both B2C and B2B environments. It offers built-in B2B functionality including company accounts, custom catalogs and pricing, quote management, and robust permissions systems, making it an excellent choice for businesses serving both markets.",
    },
    {
      question: " How long does a typical Magento implementation take?",
      answer:
        "The timeline for a Magento implementation can vary widely based on the project's complexity. A basic setup might take 2-3 months, while a more complex, custom implementation could take 4-6 months or longer. We work closely with clients to establish realistic timelines based on specific requirements.",
    },
    {
      question: "Is Magento secure for handling online transactions?",
      answer:
        "Yes, Magento is designed with security in mind and regularly releases security patches. When properly implemented and maintained, it provides a highly secure environment for e-commerce transactions. We follow best practices for secure development and offer ongoing security monitoring and updates.",
    },
    {
      question: "  Can you help with Magento hosting and server management?",
      answer:
        "Absolutely. We offer comprehensive Magento hosting solutions and server management services. This includes setting up optimized server environments, implementing caching solutions, and ensuring your store can handle high traffic volumes with minimal downtime.",
    },
  ]
  return (
    <>
      <Layout>
        <Seo
          title="Magento Development and Optimization Services"
          description="Custom Magento solutions for scalable, flexible e-commerce. 8+ years of expertise in development, migration, and optimization."
        />
        <div data-aos="fade-up">
        <PageHeading title={title} description={description}></PageHeading>
        </div>
        <div data-aos="fade-up">
          <PageIntro>
            {excerpt}
          </PageIntro>
        </div>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Core Capabilities</IHeading>
                </div>
                <div className="xl:w-2/4 lg:w-3/4" data-aos="fade-up">
                  <IParagraph>
                    Magento caters to a wide range of businesses, from small and
                    medium-sized enterprises to large corporations, across
                    various industries including retail, fashion, electronics,
                    and B2B manufacturing.
                  </IParagraph>
                </div>
              </div>
              <div className="">
                <ILine align="left" />
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center" color="white">
                Our Expertise
              </IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="center" color="white">
                With years of dedicated experience in Magento development and
                optimization, our team has established a strong reputation in
                delivering high-performance e-commerce solutions.
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
              {experties.map((item: any, index: number) => {
                return (
                  <div
                    className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                      <img src={item.imgSrc} alt="dddd" />
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <IParagraph color="gray">
                      <ul className="py-4 space-y-3 list-disc">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Services Offered</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="px-16 space-y-3 list-disc">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-28">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Why Choose Us for Magento</IHeading>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <IHeading size="subHeader" align="text-center z-10">
                      {item.title}
                    </IHeading>
                    <p className="absolute top-0 bottom-0 left-0 right-0 z-0 p-8 text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      <ul className="space-y-3 list-disc ">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Transform Your E-commerce with Magento Expertise"
          description="Ready to harness the full power of Magento for your online business? Our team of Magento experts is here to guide you through every step, from initial concept to launch and beyond."
          cta="Start Your Magento Journey"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query MagentoPageQuery {
    mdx(
      fileAbsolutePath: { regex: "/(technologies)/" }
      slug: { regex: "/(technologies/magento)/" }
    ) {
      excerpt(pruneLength: 1000)
      frontmatter {
        title
        iconName
        description
      }
    }
  }
`
export default MagentoPage
